<template>
  <div id="app">
    <pingpong />
  </div>
</template>

<script>
import pingpong from "./views/pingpong";

export default {
  name: "App",
  components: {
    pingpong,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background: aliceblue;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
